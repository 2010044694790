import {
  ALL_PRODUCT_CATEGORY_DATA,
  CREATE_CATEGORY,
  RESET_PRODUCT_CATEGORY_PARENT_DATA,
  IS_PARENT,
  SET_IS_PARENT,
  SET_IS_EDITED,
  SET_IS_RANK,
  SET_IS_ACTIVE,
  SET_IS_PARENTCATEGORY,
  SET_IS_PRODUCTCATEGORY,
  SET_IS_PLANNAME,
  SET_SELECTED_ROW_DATA,
  UPDATE_PRODUCT_CATEGORY_DATA,
  RESET_PRODUCT_CATEGORY_DATA,
  PRODUCT_CATEGORY_TYPE_DATA,
  FILTERED_CATEGORY_DATA,
  SET_RANK,
  RANK_ARRAY,
  FREELANCER_ARRAY,
  SET_SELECTED_CATEGORY,
  GET_SUBSCRIBED_VENDORS,
  GET_TOTAL_SUB_VENDORS,
  GET_TOTAL_VENDORS_PAGE,
  GET_TOTAL_VENDOR_CURRENT_PAGE,
  RESET_VENDOR_DATA
} from "../types";

const initialState = {
  productCategory: [],
  createProductCategory: {
    title: "",
    category_type: "",
    image_path: null,
    banner_image: null,
    rank: "",
    status: true,
    parent_id: null,
  },
  editProductCategory: {
    id: null,
    title: "",
    category_type: "",
    image_path: null,
    banner_image: null,
    rank: "",
    status: true,
    parent_id: null,
    is_parent: false,
  },
  is_parent: false,
  is_edited: false,
  is_rank: true,
  is_active: true,
  is_parent_category: "",
  is_product_category: "",
  is_plan_name: "",
  selected_row_data: {},
  product_category_type: [],
  filtered_data : [],
  used_rank_array : [],
  used_freelancer_array : [],
  selectedCategory: {},
  getSubscribedVendors: [],
  totalRecords: 0,
  totalPages: 0,
  vendor_current_page: 1,
};

const productcategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_PRODUCT_CATEGORY_DATA:
      return {
        ...state,
        productCategory: action.payload,
      };
    case RESET_PRODUCT_CATEGORY_PARENT_DATA:
      return {
        ...state,
        // createProductCategory: initialState.createProductCategory,
        editProductCategory: initialState.editProductCategory
      };
    case CREATE_CATEGORY:
      return {
        ...state,
        // createProductCategory: action.payload,
        editProductCategory:action.payload
      };
    case IS_PARENT:
      return {
        ...state,
        editProductCategory: {
          ...state.editProductCategory,
          is_parent: action.payload, // Update is_parent field
        },
      };
    case SET_IS_PARENT:
      return {
        ...state,
        is_parent: action.payload,
      };
    case SET_IS_EDITED:
      return {
        ...state,
        is_edited: action.payload,
      };
    case SET_IS_RANK:
      return {
        ...state,
        is_rank: action.payload,
      };
    case SET_IS_ACTIVE:
      {
      const act = {
        ...state,
        is_active: action.payload,
      };
      return act
    }
    case SET_IS_PARENTCATEGORY:
      return {
        ...state,
        is_parent_category: action.payload,
      };
    case SET_IS_PRODUCTCATEGORY:
      return {
        ...state,
        is_product_category: action.payload,
      };
    case SET_IS_PLANNAME:
      return {
        ...state,
        is_plan_name: action.payload,
      };
    case SET_SELECTED_ROW_DATA:
      return {
        ...state,
      selected_row_data : action.payload,
      };
    case UPDATE_PRODUCT_CATEGORY_DATA:
      return {
        ...state,
        editProductCategory: action.payload,
      };
    case RESET_PRODUCT_CATEGORY_DATA:
      return {
        ...state,
        editProductCategory: initialState.editProductCategory,
      };
    case PRODUCT_CATEGORY_TYPE_DATA:
      return {
        ...state,
        product_category_type: action.payload,
      };
    case FILTERED_CATEGORY_DATA:
      return {
        ...state,
        filtered_data: action.payload,
      };
    case SET_RANK:
      return {
        ...state,
        editProductCategory: {
          ...state.editProductCategory,
          rank: action.payload, // Update rank field
        },
      };
    case RANK_ARRAY:
      return {
        ...state,
        used_rank_array: action.payload
      }
    case FREELANCER_ARRAY:
      return {
        ...state,
        used_freelancer_array: action.payload
      }
    case SET_SELECTED_CATEGORY:
      return{
          ...state,
          selectedCategory: action.payload
      }
    case GET_SUBSCRIBED_VENDORS:
      return {
        ...state,
        getSubscribedVendors: action.payload
      }
    case GET_TOTAL_SUB_VENDORS:
      return {
        ...state,
        totalRecords: action.payload
      }
    case GET_TOTAL_VENDORS_PAGE:
      return {
        ...state,
        totalPages: action.payload
      }
    case GET_TOTAL_VENDOR_CURRENT_PAGE:
      return {
        ...state,
        vendor_current_page: action.payload,
      };
    case RESET_VENDOR_DATA:
      return {
        ...state,
        selectedCategory: {},
        getSubscribedVendors: [],
        totalRecords: 0,
        totalPages: 0,
        vendor_current_page: 1
      }
    default:
      return state;
  }
};

export default productcategoryReducer;
