import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Login from '../pages/Login/Login';
import DashBoard from '../pages/Dashboard/DashBoard';
import NotFound from '../component/NotFound';
// import City from "../pages/City/City";
import SocialMediaMaster from '../pages/SocialMediaMaster/SocialMediaMaster';
import EnquiryList from '../pages/Enquiry List/EnquiryList';
import RoleMaster from '../pages/RoleMaster/RoleMaster';
import CreateRole from '../pages/RoleMaster/CreateRole';
import UsersMaster from '../pages/UsersMaster/UsersMaster';
import CreateUsers from '../pages/UsersMaster/CreateUsers';
import ProductCategoryMaster from '../pages/ProductCategoryMaster/ProductCategoryMaster';
// import CreateProductCategory from "../pages/ProductCategoryMaster/CreateProductCategory";
import Plans from '../pages/Plan/Plans';
import SideList from '../pages/Dashboard/SideList';
import Product from '../pages/Product/Product';
import Otp from '../pages/Otp/Otp';
import { useSelector } from 'react-redux';
import B2BProfile from '../pages/B2BProfile/B2BProfile';
import B2BProfileView from '../pages/B2BProfile/B2BProfileView';
import FreelancerProfile from '../pages/FreelancerProfile/FreelancerProfile';
import FreelancerProfileView from '../pages/FreelancerProfile/FreelancerProfileView';
import Masters from '../pages/Masters/Masters';
import CreateMasters from '../pages/Masters/CreateMasters';
import B2BProfileViewReason from '../pages/B2BProfile/B2BProfileViewReason';
import FreelancerProfileViewReason from '../pages/FreelancerProfile/FreelancerProfileViewReason';
import HomepageWidgetForm from '../pages/HomepageWidget/HomepageWidget';
import TemplateForm from '../pages/Template/Template';
import TemplateList from '../pages/Template/TemplateList';
import Requirements from '../pages/RequirementsMaster/RequirementsMaster';
import RequirementsMaster from '../pages/RequirementsMaster/RequirementsMaster';
import ProfileForm from '../pages/Profile/Profile';
import WidgetMaster from '../pages/Widget/Widget';
import CreateWidgetMaster from '../pages/Widget/CreateWidget';
import CreateTiles from '../pages/Tiles/CreateTiles';
import ToolsServicesMaster from '../pages/ToolsServices/ToolsServicesMaster';
import SubscriptionMaster from '../pages/Subscription/Subscription';
import CreateSubscriptionMaster from '../pages/Subscription/CreateSubscription';
import ProductCatalogPdf from '../pages/B2BProfile/ProductCatalogPdf';
import AssignVendorRank from '../pages/ProductCategoryMaster/AssignVendorRank';

const routes = [
  {
    path: '/login',
    exact: true,
    sidebar: () => null,
    main: () => <Login />,
  },
  {
    path: '/product-catalog-pdf/:id',
    exact: true,
    sidebar: () => null,
    main: () => <ProductCatalogPdf />,
  },
  {
    path: '/otp',
    exact: true,
    sidebar: () => null,
    main: () => <Otp />,
  },
  {
    path: '/',
    exact: true,
    sidebar: () => <SideList />,
    main: () => <DashBoard />,
  },
  {
    path: '/product',
    sidebar: () => <SideList />,
    main: () => <Product />,
  },
  {
    path: '/plan',
    sidebar: () => <SideList />,
    main: () => <Plans />,
  },
  {
    path: '/social-media-master',
    sidebar: () => <SideList />,
    main: () => <SocialMediaMaster />,
  },
  {
    path: '/enquiry-list',
    sidebar: () => <SideList />,
    main: () => <EnquiryList />,
  },
  {
    path: '/role-master',
    sidebar: () => <SideList />,
    main: () => <RoleMaster />,
  },
  {
    path: '/create-role',
    sidebar: () => <SideList />,
    main: () => <CreateRole />,
  },
  {
    path: '/users-master',
    sidebar: () => <SideList />,
    main: () => <UsersMaster />,
  },
  {
    path: '/create-users',
    sidebar: () => <SideList />,
    main: () => <CreateUsers />,
  },

  {
    path: '/product-category-master',
    sidebar: () => <SideList />,
    main: () => <ProductCategoryMaster />,
  },
  {
    path: '/assign-vendor-rank',
    sidebar: () => <SideList />,
    main: () => <AssignVendorRank />,
  },
  {
    path: '/requirements-master',
    sidebar: () => <SideList />,
    main: () => <RequirementsMaster />,
  },
  {
    path: '/tools-services-master',
    sidebar: () => <SideList />,
    main: () => <ToolsServicesMaster />,
  },
  // {
  //   path: "/create-product-category",
  //   sidebar: () => <SideList />,
  //   main: () => <CreateProductCategory />
  // },
  {
    path: '/b2b-profile',
    exact: true,
    sidebar: () => <SideList />,
    main: () => <B2BProfile />,
  },
  {
    path: '/b2b-profile-view/:id',
    exact: true,
    sidebar: () => <SideList />,
    main: () => <B2BProfileView />,
  },
  {
    path: '/b2b-profile-view-reason',
    exact: true,
    sidebar: () => <SideList />,
    main: () => <B2BProfileViewReason />,
  },
  {
    path: '/freelancer-profile',
    exact: true,
    sidebar: () => <SideList />,
    main: () => <FreelancerProfile />,
  },
  {
    path: '/freelancer-profile-view',
    exact: true,
    sidebar: () => <SideList />,
    main: () => <FreelancerProfileView />,
  },
  {
    path: '/freelancer-profile-view-reason/:id',
    exact: true,
    sidebar: () => <SideList />,
    main: () => <FreelancerProfileViewReason />,
  },
  {
    path: '/masters',
    exact: true,
    sidebar: () => <SideList />,
    main: () => <Masters />,
  },
  {
    path: '/create-masters',
    sidebar: () => <SideList />,
    main: () => <CreateMasters />,
  },
  {
    path: '/edit-masters',
    sidebar: () => <SideList />,
    main: () => <CreateMasters />,
  },
  {
    path: '/homepage-widget',
    sidebar: () => <SideList />,
    main: () => <HomepageWidgetForm />,
  },
  {
    path: '/template-list',
    sidebar: () => <SideList />,
    main: () => <TemplateList />,
  },
  {
    path: '/create-template',
    sidebar: () => <SideList />,
    main: () => <TemplateForm />,
  },
  {
    path: '/profile',
    sidebar: () => <SideList />,
    main: () => <ProfileForm />,
  },
  {
    path: '/widget-master',
    sidebar: () => <SideList />,
    main: () => <WidgetMaster />,
  },
  {
    path: '/create-widget',
    sidebar: () => <SideList />,
    main: () => <CreateWidgetMaster />,
  },
  {
    path: '/create-tiles',
    sidebar: () => <SideList />,
    main: () => <CreateTiles />,
  },
  {
    path: '/subscription-master',
    sidebar: () => <SideList />,
    main: () => <SubscriptionMaster />,
  },
  {
    path: '/create-subscription',
    sidebar: () => <SideList />,
    main: () => <CreateSubscriptionMaster />,
  },
  {
    path: '*',
    sidebar: () => null,
    main: () => <NotFound />,
  },
];

function Routers() {
  const { userData } = useSelector((state) => state.signin);

  function PrivateRoute({ children, ...rest }) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          userData ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  }

  return (
    <BrowserRouter>
      <div>
        <Switch>
          {routes.map((route, index) => {
            // You can render a <Route> in as many places
            // as you want in your app. It will render along
            // with any other <Route>s that also match the URL.
            // So, a sidebar or breadcrumbs or anything else
            // that requires you to render multiple things
            // in multiple places at the same URL is nothing
            // more than multiple <Route>s.
            if (
              route.path === '/login' ||
              route.path === '/otp' ||
              route.path === '*'
            ) {
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  children={<route.sidebar />}
                />
              );
            } else {
              return (
                <PrivateRoute
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  children={<route.sidebar />}
                />
              );
            }
          })}
        </Switch>
      </div>
      <div>
        <Switch>
          {routes.map((route, index) => {
            // Render more <Route>s with the same paths as
            // above, but different components this time.

            if (
              route.path === '/login' ||
              route.path === '/otp' ||
              route.path === '*'
            ) {
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  children={<route.main />}
                />
              );
            } else {
              return (
                <PrivateRoute
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  children={<route.main />}
                />
              );
            }
          })}
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default Routers;
