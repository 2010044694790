import api from './configs/axiosConfig';

const product_category = '/categories';

const put_product_category = '/categories/';
const assign_rank = '/admin/assign-rank';
const get_subscribed_vendors = '/admin/get-subscribed-vendors/';
// const search_subscribed_vendors = '/admin/search-subscribed-vendors/'
const headerConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};
const jsonHeaderConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const ProductCategoryAPI = {
  getCategory: async function () {
    const response = await api.get(product_category);
    return response;
  },
  postCategory: async function (data) {
    const response = await api.post(product_category, data, headerConfig);
    return response;
  },
  editCategory: async function (id, data) {
    const response = await api.put(
      put_product_category + id,
      data,
      headerConfig
    );
    return response;
  },
  assignRank: async function (data) {
    const response = await api.post(assign_rank, data, jsonHeaderConfig);
    return response;
  },
  getSubscribedVendors: async function (data, page, pageSize) {
    const response = await api.get(`${get_subscribed_vendors}${data}?page=${page}&pageSize=${pageSize}`);
    return response;
  },
  searchSubscribedVendors: async function (data, search, page, pageSize) {
    const response = await api.get(`${get_subscribed_vendors}${data}?q=${search}&page=${page}&pageSize=${pageSize}`);
    return response;
  }
};
