import { ProductCategoryAPI } from '../../../../apis/ProductCategoryAPI';
import { pushMessage } from '../../../../component/MessageBox/redux/actions/message-box-action';
import { SET_LOADING } from '../../../../store/types';
import {
  ALL_PRODUCT_CATEGORY_DATA,
  CREATE_CATEGORY,
  RESET_PRODUCT_CATEGORY_PARENT_DATA,
  IS_PARENT,
  SET_IS_PARENT,
  SET_IS_EDITED,
  SET_IS_RANK,
  SET_IS_ACTIVE,
  SET_IS_PARENTCATEGORY,
  SET_IS_PRODUCTCATEGORY,
  SET_IS_PLANNAME,
  SET_SELECTED_ROW_DATA,
  RESET_PRODUCT_CATEGORY_DATA,
  UPDATE_PRODUCT_CATEGORY_DATA,
  PRODUCT_CATEGORY_TYPE_DATA,
  FILTERED_CATEGORY_DATA,
  SET_RANK,
  RANK_ARRAY,
  FREELANCER_ARRAY,
  SET_SELECTED_CATEGORY,
  GET_SUBSCRIBED_VENDORS,
  GET_TOTAL_SUB_VENDORS,
  GET_TOTAL_VENDORS_PAGE,
  GET_TOTAL_VENDOR_CURRENT_PAGE,
  RESET_VENDOR_DATA,
} from '../types';

export const saveCategory = (insertdata) => {
  return (dispatch) => {
    dispatch({ type: CREATE_CATEGORY, payload: insertdata });
  };
};

export const saveEditCategory = (insertdata) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_PRODUCT_CATEGORY_DATA, payload: insertdata });
  };
};

export const resetCategory = () => {
  return (dispatch) => {
    dispatch({ type: RESET_PRODUCT_CATEGORY_PARENT_DATA });
  };
};

export const saveIsParent = (value) => {
  return (dispatch) => {
    dispatch({ type: IS_PARENT, payload: value });
  };
};

export const setParent = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_IS_PARENT, payload: value });
  };
};

export const setEdited = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_IS_EDITED, payload: value });
  };
};

export const setRank = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_IS_RANK, payload: value });
  };
};

export const setActive = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_IS_ACTIVE, payload: value });
  };
};

export const setParentCategory = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_IS_PARENTCATEGORY, payload: value });
  };
};

export const setProductCategory = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_IS_PRODUCTCATEGORY, payload: value });
  };
};

export const setPlanName = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_IS_PLANNAME, payload: value });
  };
};

export const setSelectedRowData = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_SELECTED_ROW_DATA, payload: value });
  };
};

export const setProductCategoryType = (obj) => {
  return (dispatch) => {
    dispatch({ type: PRODUCT_CATEGORY_TYPE_DATA, payload: obj });
  };
};

export const setFilteredProductCategory = (obj) => {
  return (dispatch) => {
    dispatch({ type: FILTERED_CATEGORY_DATA, payload: obj });
  };
};

export const setEditRank = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_RANK, payload: value });
  };
};

export const setRankArray = (value) => {
  return (dispatch) => {
    dispatch({ type: RANK_ARRAY, payload: value });
  };
};

export const setFreelancerArray = (value) => {
  return (dispatch) => {
    dispatch({ type: FREELANCER_ARRAY, payload: value });
  };
};

export const getProductCategoryData = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    try {
      const response = await ProductCategoryAPI.getCategory();
      if (response.status === 200) {
        dispatch({
          type: ALL_PRODUCT_CATEGORY_DATA,
          payload: response.data.data,
        });
        dispatch({ type: SET_LOADING, payload: false });
      } else {
        dispatch({ type: SET_LOADING, payload: false });
      }
    } catch (error) {
      console.log('error :', error);
      dispatch({ type: SET_LOADING, payload: false });
    }
  };
};

export const createProductCategoryData = (
  data,
  successCallback = () => {},
  errorCallback = () => {}
) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    try {
      const formdata = new FormData();
      formdata.append('title', data.title);
      formdata.append('category_type', data.category_type);
      formdata.append('image_path', data?.image_path);
      formdata.append('banner_image', data?.banner_image);
      formdata.append('rank', Number(data?.rank));
      formdata.append('status', Boolean(data?.status));
      if (data.parent_id !== null) {
        formdata.append('parent_id', Number(data?.parent_id));
      }
      const response = await ProductCategoryAPI.postCategory(formdata);
      if (response.status === 201) {
        dispatch({ type: RESET_PRODUCT_CATEGORY_PARENT_DATA });
        dispatch({ type: SET_LOADING, payload: false });
        dispatch(pushMessage('Created SuccessFully'));
        successCallback(response.data);
      } else {
        dispatch({ type: SET_LOADING, payload: false });
      }
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
      console.log('error is', error);
      errorCallback(error);
    }
  };
};

export const putProductCategoryData = (
  data,
  successCallback = () => {},
  errorCallback = () => {}
) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    try {
      const formdata = new FormData();
      if (data.image_path !== null) {
        formdata.append('image_path', data?.image_path);
      }
      if (data.banner_image !== null) {
        formdata.append('banner_image', data?.banner_image);
      }
      if (data.rank !== 0) {
        formdata.append('rank', Number(data?.rank));
      }
      formdata.append('status', Boolean(data?.status));
      const response = await ProductCategoryAPI.editCategory(data.id, data);
      if (response.status === 200) {
        dispatch(pushMessage('Updated SuccessFully'));
        successCallback(response.data);
        dispatch({ type: RESET_PRODUCT_CATEGORY_DATA });
        dispatch({ type: SET_LOADING, payload: false });
      } else {
        dispatch({ type: SET_LOADING, payload: false });
      }
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
      console.log('error is', error);
      errorCallback(error.header.data);
    }
  };
};

export const setSelectedCategory = (data) => {
  return (dispatch) => {
    dispatch({ type: SET_SELECTED_CATEGORY, payload: data });
  };
};

export const assignRankToVendor = (
  data,
  successCallback = () => {},
  errorCallback = () => {}
) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    try {
      const response = await ProductCategoryAPI.assignRank(data);
      if (response.status === 200) {
        dispatch({ type: SET_LOADING, payload: false });
        dispatch(pushMessage('Created SuccessFully'));
        successCallback(response.data);
      } else {
        dispatch({ type: SET_LOADING, payload: false });
      }
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
      errorCallback(error?.response);
    }
  };
};

export const getSubscribedVendorsList = (
  id,
  page,
  pageSize,
  successCallback = () => {},
  errorCallback = () => {}
) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    try {
      const response = await ProductCategoryAPI.getSubscribedVendors(id, page, pageSize);
      if (response.status === 200) {
        dispatch({
          type: GET_SUBSCRIBED_VENDORS,
          payload: response?.data?.data,
        });
        dispatch({
          type: GET_TOTAL_SUB_VENDORS,
          payload: response?.data?.totalRecords,
        });
        dispatch({
          type: GET_TOTAL_VENDOR_CURRENT_PAGE,
          payload: page,
        });
        successCallback(response.data);
        dispatch({ type: SET_LOADING, payload: false });
      } else {
        dispatch({ type: SET_LOADING, payload: false });
      }
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
      errorCallback(error?.response?.data);
    }
  };
};

export const resetAssignRankData = () => {
  return (dispatch) => {
    dispatch({ type: RESET_VENDOR_DATA, payload: {} });
  };
};

export const searchSubscribedVendorsList = (
  id,
  text,
  page,
  pageSize,
  successCallback = () => {},
  errorCallback = () => {}
) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    try {
      const response = await ProductCategoryAPI.searchSubscribedVendors(id, text, page, pageSize);
      if (response.status === 200) {
        dispatch({
          type: GET_SUBSCRIBED_VENDORS,
          payload: response?.data?.data,
        });
        dispatch({
          type: GET_TOTAL_SUB_VENDORS,
          payload: response?.data?.totalRecords,
        });
        dispatch({
          type: GET_TOTAL_VENDOR_CURRENT_PAGE,
          payload: page,
        });
        successCallback(response.data);
        dispatch({ type: SET_LOADING, payload: false });
      } else {
        dispatch({ type: SET_LOADING, payload: false });
      }
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
      errorCallback(error?.response?.data);
    }
  };
};