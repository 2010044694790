import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  FormControl,
  Grid,
  Link,
  MenuItem,
  Pagination,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ReactComponent as EditIcon } from '../../assets/edit-icon.svg';
import CustomPagination from '../../component/CustomPagination/CustomPagination';
import { useHistory } from 'react-router-dom';
import SideBarContent from '../../component/SideBarContent/SideBarContent';
import { connect } from 'react-redux';
import { setHeaderTitle } from '../../store/actions/rootActions';
import {
  assignRankToVendor,
  getSubscribedVendorsList,
  resetAssignRankData,
  searchSubscribedVendorsList,
} from './redux/actions/productcategory-action';
import { DIALOG_TITLE, DIALOG_VENDOR_MSG } from '../../utils/constants';
import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg';

const AssignVendorRank = ({
  data = [],
  vendor_current_page,
  selectedCategory,
  assignRankToVendor,
  getSubscribedVendorsList,
  getSubscribedVendors,
  totalRecords,
  searchSubscribedVendorsList,
  resetAssignRankData,
}) => {
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10); // Since data is static, we don't need to change rowsPerPage
  const [currentPage, setCurrentPage] = useState(0);
  const [availableRanks, setAvailableRanks] = useState(
    [...Array(20).keys()].map((rank) => rank + 1)
  );
  const [debouncedSearchText, setDebouncedSearchText] = useState('');
  const [loading, setLoading] = useState(false);
  const [openSuccCallModal, setOpenSuccCallModal] = useState(false);
  const handleOpenSuccCallModal = () => setOpenSuccCallModal(true);
  const handleCloseSuccCallModal = () => setOpenSuccCallModal(false);
  const history = useHistory();
  const debounceSearch = React.useCallback((value) => {
    const handler = setTimeout(() => {
      setDebouncedSearchText(value);
      setLoading(false);
    }, 500);

    return () => {
      clearTimeout(handler);
      setLoading(false);
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    const cancelDebounce = debounceSearch(searchText);
    return cancelDebounce;
  }, [searchText, debounceSearch]);

  useEffect(() => {
    if (debouncedSearchText !== '') {
      searchSubscribedVendorsList(
        selectedCategory?.id,
        debouncedSearchText,
        1,
        rowsPerPage
      );
    } else {
      getSubscribedVendorsList(
        selectedCategory?.id,
        vendor_current_page,
        rowsPerPage
      );
    }
    setAvailableRanks(
      [...Array(21).keys()].map((rank) => (rank == 0 ? 'Not Assigned' : rank))
    );
  }, [debouncedSearchText, rowsPerPage]);

  //   useEffect(() => {
  //     //API call for get vendor list by category id.
  //     const fetchData = async () => {
  //       await getSubscribedVendorsList(
  //         selectedCategory?.id,
  //         vendor_current_page,
  //         rowsPerPage
  //       );
  //     };
  //     fetchData();
  //     setAvailableRanks(
  //       [...Array(21).keys()].map((rank) => (rank == 0 ? 'Not Assigned' : rank))
  //     );
  //   }, []);
  useEffect(() => {
    if (searchText === '') {
      setPage(currentPage);
    } else {
      setPage(0);
    }
  }, [searchText]);

  const handleSearch = (e) => {
    const searchTerm = e.target.value.replace(/\s\s+/g, ' ');
    setSearchText(searchTerm, 'searchValue');
  };

  const handleChangePage = (newPage) => {
    if (debouncedSearchText !== '') {
      searchSubscribedVendorsList(
        selectedCategory?.id,
        debouncedSearchText,
        newPage,
        rowsPerPage
      );
    } else {
      getSubscribedVendorsList(selectedCategory?.id, newPage, rowsPerPage);
    }
  };

  const successCallback = async (data) => {
    // alert("success");
    await getSubscribedVendorsList(
      selectedCategory?.id,
      vendor_current_page,
      rowsPerPage
    );
  };

  const errorCallback = (error) => {
    // alert("fail");
    if (error?.status == 400) {
      handleOpenSuccCallModal();
    }
  };

  const handleRankChange = async (index, newRank, vendor) => {
    // Implement state update logic if needed
    if (newRank == 'Reset') {
      newRank = 0;
    }
    const jsonData = JSON.stringify({
      categoryId: selectedCategory?.id?.toString(),
      vendorId: vendor?.vendor?.id?.toString() || '0',
      rank: newRank == 'Not Assigned' ? 0 : newRank,
    });
    await assignRankToVendor(jsonData, successCallback, errorCallback);
  };

  const handleBack = () => {
    resetAssignRankData();
    history.goBack();
  };

  return (
    <SideBarContent>
      <Dialog
        open={openSuccCallModal}
        onClose={handleCloseSuccCallModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle className="custom-border-header pb-0">
          <Box className="position-relative d-flex justify-content-between">
            <Typography
              className="fs-22 fw-bold font-skmodernist"
              id="alert-dialog-title"
            >
              {DIALOG_TITLE}
            </Typography>
            <Box>
              <CloseIcon
                onClick={() => {
                  handleCloseSuccCallModal();
                }}
                style={{ cursor: 'pointer' }}
              />
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {DIALOG_VENDOR_MSG}
          </DialogContentText>
        </DialogContent>

        <DialogActions className="modal-footer">
          <Button
            onClick={() => {
              handleCloseSuccCallModal();
            }}
            className="btn-save"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Box className="custom-card-bg">
        <Box className="mb-4">
          <Grid
            container
            spacing={2}
            className="align-items-center just-content-between"
          >
            <Grid item xs={10} md={6}>
              <TextField
                sx={{ height: '50px', width: '400px', borderRadius: '6px' }}
                variant="outlined"
                placeholder="Search here..."
                value={searchText}
                onChange={handleSearch}
                InputProps={{
                  startAdornment: (
                    <Search
                      sx={{
                        width: '1em',
                        height: '1em',
                        marginRight: '8px',
                        color: '#864FFD',
                      }}
                      color="action"
                    />
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <Paper sx={{ width: '100%' }}>
          <TableContainer sx={{ maxHeight: '450px' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Vendor Name</TableCell>
                  <TableCell>Vendor Rank</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getSubscribedVendors?.map((vendor, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {vendor?.vendor?.company_name
                        ? vendor?.vendor?.company_name
                        : vendor?.vendor?.name}
                    </TableCell>
                    <TableCell>
                      <FormControl sx={{ width: '200px' }}>
                        <Select
                          value={
                            vendor?.rank == null ? 'Not Assigned' : vendor?.rank
                          }
                          onChange={(e) =>
                            handleRankChange(index, e.target.value, vendor)
                          }
                          // sx={{ width: "80px" }}
                        >
                          {availableRanks.map((num) => (
                            <MenuItem key={num} value={num}>
                              {num}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomPagination
            page={vendor_current_page}
            count={totalRecords || 0}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
          />
        </Paper>

        <Box className="d-flex justify-content-start mt-4">
          <Button className="btn-save" onClick={handleBack}>
            Back
          </Button>
          {/* <Button  className="btn-cancel me-2">
                        Cancel
                    </Button>
                    <Button  className="btn-save">
                        Save
                    </Button> */}
        </Box>
      </Box>
    </SideBarContent>
  );
};

const mapStateToProps = (state) => {
  const { all_role, all_module, all_role_filter } = state.role;
  const {
    selectedCategory,
    getSubscribedVendors,
    vendor_current_page,
    totalRecords,
  } = state.productcategory;
  return {
    all_role,
    all_module,
    all_role_filter,
    selectedCategory,
    getSubscribedVendors,
    vendor_current_page,
    totalRecords,
  };
};

export default connect(mapStateToProps, {
  assignRankToVendor,
  getSubscribedVendorsList,
  searchSubscribedVendorsList,
  resetAssignRankData,
})(AssignVendorRank);
